@import url("https://fonts.googleapis.com/css2?family=Questrial");

:root {
  --light: #f6f6f6;
  --dark: #161616;

  --primary: #ff7ffa;
  --secondary: #ff7fc9;
  --tertiary: #ffeb80;
  --quaternary: #8e6eba;
  --quinary: #80a8ff;
  --senary: #7fffde;

  --light-rgb: 246, 246, 246;
  --dark-rgb: 22, 22, 22;

  --primary-rgb: 255, 127, 250;
  --secondary-rgb: 255, 127, 201;
  --tertiary-rgb: 255, 235, 128;
  --quaternary-rgb: 142, 110, 186;
  --quinary-rgb: 128, 168, 255;
  --senary-rgb: 127, 255, 222;
}

* {
  font-family: "Questrial", sans-serif;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  background-color: rgba(var(--quinary-rgb), 0.2);
}

.navbar {
  padding: 0px !important;
  transition: 0.3s ease;
  background-color: rgba(var(--dark-rgb), 0.25) !important;
}

.navbar:hover {
  background-color: rgba(var(--dark-rgb), 0.5) !important;
}

.navbar-toggler-icon {
  border: none;
  font-size: 32px;
  margin-top: 10px;
}

.nav-hidden {
  opacity: 0;
}

.nav-link {
  margin: 40px 20px !important;
  padding: 0px !important;

  font-size: 20px;
  font-weight: 600;
  letter-spacing: 5px;
  color: var(--dark) !important;
  text-transform: uppercase;
}

.nav-list li:hover:first-child a {
  color: var(--primary) !important;
}

.nav-list li:nth-child(1) + li:hover a {
  color: var(--secondary) !important;
}

.nav-list li:nth-child(2) + li:hover a {
  color: var(--tertiary) !important;
}

.nav-list li:nth-child(3) + li:hover a {
  color: var(--quaternary) !important;
}

.nav-list li:hover:last-child a {
  color: var(--quinary) !important;
}

.primary-btn {
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 3px;

  outline: none;
  border: 2px solid var(--dark);
  border-radius: 50px;

  padding: 20px 30px;
  min-width: 120px;
  min-height: 75px;

  animation: btn-anim 8s infinite;
}

.primary-btn:disabled {
  cursor: not-allowed;
}

.primary-p {
  color: var(--dark);
  font-size: 21px;
  text-align: "center";
  line-height: 40px;

  width: 40%;
}

@keyframes btn-anim {
  0% {
    background-color: var(--primary);
  }
  25% {
    background-color: var(--secondary);
  }
  50% {
    background-color: var(--tertiary);
  }
  75% {
    background-color: var(--quaternary);
  }
  100% {
    background-color: var(--quinary);
  }
}

.primary-h {
  text-transform: uppercase;
  letter-spacing: 6px;
  color: var(--dark);
  font-size: 60px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;

  margin: 0;
  user-select: none;

  transition: all 0.25s ease-out;
}

.primary-h:hover {
  letter-spacing: 8px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.faq-container {
  width: 60%;
  margin: 40px auto;
}

.faq-item {
  color: var(--dark);
  background-color: transparent;
  overflow: hidden;
  margin: 20px;
  border: 3px solid var(--dark);
  border-radius: 50px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.faq-label {
  position: relative;
  margin: 0;
  display: block;
  width: 100%;
  padding: 1em;
  letter-spacing: 0.5px;
  color: var(--dark);
  font-size: 26px;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.faq-text {
  padding: 0px 15px;
  margin: 0px 0;
  width: 100% !important;
  max-height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  color: var(--dark);
  font-size: 20px;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.faq-title:checked ~ .faq-text {
  max-height: 600px;
  opacity: 1;
  z-index: 0;
  padding: 15px;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.faq-title:checked ~ .faq-label {
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  padding-bottom: 0em;
}

.faq-icon {
  position: absolute;
  margin-top: 15px;
  margin-left: 30px;
  z-index: 5;
  font-size: 3em;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.faq-title:checked ~ .faq-icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.faq-title {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(var(--light-rgb), 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(var(--dark-rgb), 0.8);
  border-radius: 50px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.social-menu ul {
  display: flex;
  justify-content: center;
}

.social-menu ul li {
  list-style: none;
  margin: 0;
  color: var(--secondary);
}

.social-menu ul li .fab {
  font-size: 24px;
  line-height: 48px;
  transition: 0.2s;
}

.social-menu ul li a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  color: var(--secondary);
  transition: 0.1s;
}

.social-menu ul li a:hover {
  transform: translateY(-3px);
  color: var(--primary);
  transition: 0.1s;
}

.mint-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 45px 30px;
  background: rgba(var(--tertiary-rgb), 0.6);
  border: 2px solid rgba(var(--dark-rgb), 0.2);
  border-radius: 50px;
  color: var(--dark);
}

.mint-container .content {
  align-self: center;
}

.mint-container h1 {
  font-size: 42px;
}

.mint-container .quantity-btn {
  display: inline-block;
  margin: 0px;
  width: 50px;
  height: 50px;
  text-align: center;
  outline: none;
  background: var(--quaternary);
  border: 2px solid var(--dark);
  border-radius: 50px;
  vertical-align: middle;
  font-size: 20px;
  padding: 8px 0;
  margin: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.2s ease;
}

.mint-container .quantity-btn:hover {
  background: var(--quinary);
}

.mint-container .quantity-btn:disabled {
  cursor: not-allowed;
}

.mint-container .quantity {
  text-align: center;
  border: 2px solid var(--dark);
  border-radius: 50px;
  background-color: var(--light);
  margin: 10px;
  font-size: 20px;
  padding-top: 8px;
  width: 50px;
  height: 50px;
}

.form-input {
  background-color: transparent;
  border: 2px solid var(--dark);
  border-radius: 50px;
  padding: 20px 20px;
  width: 40%;
  font-size: 20px;
  color: var(--dark);
  margin: 10px;
  outline: none;
  transition: 0.3s ease;
}

.form-input:focus,
.form-input:hover {
  background: var(--light);
}

.alert.error {
  color: var(--accentDark);
}

.alert.sending {
  color: var(--accent);
}

.alert.success {
  color: var(--accentLight);
}

.error {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #d60000;
}

@media screen and (max-width: 380px) {
  .mint-container {
    padding: 18px !important;
  }
}

@media only screen and (max-width: 900px) {
  .mint-container {
    padding: 45px;
    width: 90vw;
  }

  .faq-container {
    width: 90%;
  }

  .faq-icon {
    display: none;
  }

  .primary-p {
    width: 100%;
  }

  .primary-btn {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 3px;

    outline: none;
    border: 2px solid var(--dark);
    border-radius: 50px;

    padding: 10px 20px;

    animation: btn-anim 8s infinite;
  }
}

@media only screen and (max-width: 992px) {
  .primary-h {
    font-size: 42px;
  }

  p {
    font-size: 20px !important;
  }

  html {
    overflow: scroll;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
