.nav-item {
  .social-nav {
    background: transparent;
    position: relative;
    display: block;
    border-radius: 50%;
    border: 2px solid var(--dark);
    padding: 25px;
    height: 40px;
    width: 40px;
    margin-left: 15px;
    transition: all 0.25s ease;

    span {
      position: absolute;
      margin-top: 2px;
      font-size: 22px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: rgba(var(--dark-rgb), 0.9);
    }

    &:hover {
      transform: scale(1.1);
      color: var(--light);
    }
  }
}

.roadmap {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: inherit;
  }
}

.roadmap-item {
  padding-left: 40px;
  position: relative;
  &:last-child {
    padding-bottom: 0;
  }
}

.roadmap-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;

  &:before {
    background: var(--primary);
    border: 3px solid transparent;
    border-radius: 100%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    top: 4px;
    left: 0;
    width: 15px;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
  }

  &:after {
    content: "";
    width: 3px;
    background: var(--dark);
    display: block;
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 6px;
  }

  .roadmap-item:last-child &:after {
    content: none;
  }
}

.roadmap-item:not(.period):hover .roadmap-marker:before {
  background: transparent;
  border: 3px solid var(--primary);
}

.roadmap-content {
  font-size: 19px;
  padding-bottom: 40px;
  line-height: 32px;
  p:last-child {
    margin-bottom: 0;
  }
}

.roadmap-centered {
  @media (min-width: 992px) {
    &,
    .roadmap-item,
    .roadmap-info,
    .roadmap-marker,
    .roadmap-content {
      display: block;
      margin: 0;
      padding: 0;
    }

    .roadmap-item {
      padding-bottom: 40px;
      overflow: hidden;
    }

    .roadmap-marker {
      position: absolute;
      left: 50%;
      margin-left: -7.5px;
    }

    .roadmap-info,
    .roadmap-content {
      width: 50%;
    }

    > .roadmap-item:nth-child(odd) .roadmap-info {
      float: left;
      text-align: right;
      padding-right: 30px;
    }

    > .roadmap-item:nth-child(odd) .roadmap-content {
      float: right;
      text-align: left;
      padding-left: 30px;
    }

    > .roadmap-item:nth-child(even) .roadmap-info {
      float: right;
      text-align: left;
      padding-left: 30px;
    }

    > .roadmap-item:nth-child(even) .roadmap-content {
      float: left;
      text-align: right;
      padding-right: 30px;
    }

    > .roadmap-item.period .roadmap-content {
      float: none;
      padding: 0;
      width: 100%;
      text-align: center;
    }

    .roadmap-item.period {
      padding: 50px 0 90px;
    }

    .period .roadmap-marker:after {
      height: 30px;
      bottom: 0;
      top: auto;
    }

    .period .roadmap-title {
      left: auto;
    }
  }
}

.profile-card {
  position: relative;
  width: 300px;
  margin: 0 auto;
  padding: 40px 30px 30px;
  text-align: center;
  transition: all 200ms ease;
}

.profile-card:hover {
  transform: rotate(1deg);
}

.profile-card header {
  display: block;
  margin-bottom: 10px;
}

.profile-card header img {
  border-radius: 50%;
  width: 225px;
  height: 225px;
  margin-bottom: 20px;
  overflow: hidden;
}

.profile-card header h1 {
  font-size: 36px;
  color: var(--dark);
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.profile-card header h2 {
  font-size: 18px;
  color: var(--dark);
  margin: 0;
}

.profile-social-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

.profile-social-links li {
  display: inline-block;
  margin: 0 10px;
}

.profile-social-links li a {
  background: transparent;
  font-size: 24px;
  color: var(--dark);
  cursor: pointer;
  -webkit-transition: all 2.75s cubic-bezier(0, 0.83, 0.17, 1);
  -moz-transition: all 2.75s cubic-bezier(0, 0.83, 0.17, 1);
  -o-transition: all 2.75s cubic-bezier(0, 0.83, 0.17, 1);
  transition: all 2.75s cubic-bezier(0, 0.83, 0.17, 1);
  transform-style: preserve-3d;
}

.profile-social-links li a:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
}

.pagination-btn {
  display: flex;
  list-style: none;
  margin-top: 40px;
  margin-left: -40px;
}

.pagination-btn a {
  cursor: pointer;
  font-size: 14px;
  padding: 10px 10px;
  border-width: 1px;
  margin: 0px 3px;
  border-radius: 5px;
  color: var(--dark) !important;
  background-color: transparent;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.pagination-btn a:hover {
  color: var(--primary);
}

.pagination-active a {
  background-color: var(--quinary) !important;
  color: var(--dark) !important;
}

.pagination-disabled a {
  cursor: not-allowed !important;
  background-color: rgba(var(--dark-rgb), 0.25) !important;
  color: var(--light) !important;
}

.loading-spinner {
  border: 8px solid rgba(var(--light-rgb), 0.5);
  border-radius: 50%;
  border-top: 8px solid var(--dark);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
  animation: spin 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footer-bar {
  background: var(--dark) !important;
  padding-top: 20px;
  max-height: 100%;
}

.footer-bar a {
  text-decoration: none;
  color: rgba(var(--light-rgb), 0.9);
}

.footer-bar .copyright {
  color: rgba(var(--light-rgb), 0.6);
}

.footer-bar .footer-heading {
  font-size: 36px;
  color: var(--light);
  font-weight: 700;
  margin-bottom: 30px;
}

.footer-bar .menu {
  margin-bottom: 30px;
}

.footer-bar .menu a {
  color: rgba(var(--light-rgb), 0.9);
  margin: 0 10px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.25s ease;
}

.footer-bar .menu a:hover {
  color: rgba(var(--light-rgb), 0.5);
}

.footer-bar-social {
  li {
    list-style: none;
    margin: 0 20px 0 0;
    display: inline-block;

    a {
      background: transparent;

      position: relative;
      display: block;

      border-radius: 50%;
      border: 2px solid var(--light);

      padding: 25px;
      height: 40px;
      width: 40px;

      transition: all 0.25s ease;

      span {
        position: absolute;
        margin-top: 2px;
        font-size: 22px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        transform: scale(1.1);
        color: var(--light);
      }
    }
  }
}

$animationSpeed: 25s;

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  background: transparent;
  height: 125px;
  margin: 30px auto;
  overflow: hidden;
  position: relative;
  width: 35%;

  @media (max-width: 992px) {
    width: 100%;
  }

  &::before,
  &::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slide {
    width: 200px;
  }
}
